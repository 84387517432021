var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "security" },
    [
      _c("header", [_c("header-nav")], 1),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("footer-bar"),
      _vm._v(" "),
      _c("easemob", { attrs: { easemob: _vm.easemob } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "content" }, [
      _c("img", { attrs: { src: require("./img/img-side-huaxia.jpg") } }),
      _vm._v(" "),
      _c("h3", [
        _vm._v(
          "Fund Deposit Management Program with Huaxia Bank Beijing Branch"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "To further compliance with evolving online lending regulatory requirements, Yangqianguan launched Fund Deposit Management\n            Program with Huaxia Bank Beijing branch on October 28th, 2017. A two-layer account system is set and managed by Huaxia Bank\n            Beijing branch with the first layer being custody account under Yanqianguan’s name, and the second layer being sub-accounts\n            for investors and borrowers on the platform in order to separate Yanqianguan’s own capital from funding on the platform"
        ),
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: require("./img/img-side-kunlun.png") } }),
      _vm._v(" "),
      _c("h3", [
        _vm._v(
          "Hundreds of Millions Funding from Zhou Yahui, president of BEIJING KUNLUN TECH CO.,LTD"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Leveraging the hundreds of millions funding from Mr. Zhou Yahui and extensive channel advantage on Fintech service industry\n            of BEIJING KUNLUN TECH CO.,LTD., Yanqianguan is able to provide superior and comprehensive service to customers on the platform"
        ),
      ]),
      _vm._v(" "),
      _c("h3", [
        _vm._v("Customer Protection through Insurance Agreement Program"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "In the case of stolen risk and embezzlement risk during transaction procedure, all users with real name registration and\n            bank card binding are under protection of insurance agreement program provided by Anxin Insurance Company"
        ),
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("National Fintech Platform Disclosure Program")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The National Committee of Experts on the Internet Financial Security Technology which is sponsored by the National Internet\n            Emergency Center and the Internet Society of China launched National Fintech Disclosure Program in April 2017. The program\n            aims to improve fintech platforms’ operating transparency by involving individual investor into supervision process and publishing\n            platforms’ operation information timely. Yangqianguan is at the first batch participating in the program. Investment records\n            can be accessible to investors through Investment Security Assistant App(download from https://www.ifcert.org.cn/platform/toFinancialPublicity"
        ),
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: require("./img/img-side-company.png") } }),
      _vm._v(" "),
      _c("h3", [_vm._v("World-class Technology Team")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "With the core technology team consisting of talents from world leading internet companies such as Facebook, Google, Apple,\n            Microsoft, SAP, Oracle, Tencent, etc., we adopt a secure system architecture and ensure clients superior user experience"
        ),
      ]),
      _vm._v(" "),
      _c("h3", [
        _vm._v(
          "Level-3 Certificate on National information system security Protection"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Yangqianguan’s business system as well as its joint system with Huaxia Bank achieved the Level 3 Certificate which is the\n            highest level for non-financial institutions on National information system security Protection authenticated by the Ministry\n            of Public Security"
        ),
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: require("./img/img-side-ali.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }